import React, { useContext } from "react";

const BB_API = {
  // DEV
  api_url: "https://hq.binarybrigade.pt/api/api.php",
  upload_url: "https://hq.binarybrigade.pt/upload/upload.php",

  login: async (properties = {}) => {
    const response = await fetch(BB_API.api_url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(properties),
    });
    return response.json();
  },

  operation: async (properties = {}) => {
    const token = localStorage.getItem("bbhq_token") || "";
    
    const response = await fetch(BB_API.api_url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(properties),
    });
    return response.json();
  },
};

export default BB_API;
