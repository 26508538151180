import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

function Brevemente(props) {
    return (
      <p>Não Implementado / Brevemente ... </p>
    );
  }

  export default Brevemente;