import React, { Suspense, useContext, useEffect } from "react";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ProTip from "./ProTip";
import CircularProgress from "@mui/material/CircularProgress";

import AuthContext from "./auth/auth-context";
import SignIn from "./SignIn";
import { Dashboard } from "@mui/icons-material";
import DashboardPage from "./DashboardPage";
import Brevemente from "./Brevemente";
import NotFoundPage from "./NotFoundPage";
import { AuthContextProvider } from "./auth/auth-context";
import { DashboardSubPage } from "./DashboardSubPage";
import SubPageInicio from "./SubPageInicio";
import SubPageClientes from "./SubPageClientes";
import SubPageClientesAdicionar from "./SubPageClientesAdicionar";
import SubPageClientesEditar from "./SubPageClientesEditar";
import SubPageSddExport from "./SubPageSddExport";
import SubPageSDDValidate from "./SubPageSddValidate";

function RequireAuth({ children }) {
  const authCtx = useContext(AuthContext);
  let location = useLocation();
  if (!authCtx.isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
}

export default function App() {
  // const history = useNavigate();
  // const navigate = useNavigate();

  return (
    <AuthContextProvider>
      <Suspense fallback={<CircularProgress disableShrink />}>
        <Routes>
          <Route path="/login" exact element={<SignIn />} />
          <Route path="/recuperar-password" exact element={<Brevemente />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                <DashboardSubPage>
                  <SubPageInicio />
                </DashboardSubPage>
              </RequireAuth>
            }
          />
          <Route
            path="/clientes"
            element={
              <RequireAuth>
                <DashboardSubPage>
                  <SubPageClientes />
                </DashboardSubPage>
              </RequireAuth>
            }
          />
          <Route
            path="/clientes-adicionar"
            element={
              <RequireAuth>
                <DashboardSubPage>
                  <SubPageClientesAdicionar />
                </DashboardSubPage>
              </RequireAuth>
            }
          />

          <Route
            path="/clientes-editar/:cliente_id"
            element={
              <RequireAuth>
                <DashboardSubPage>
                  <SubPageClientesEditar />
                </DashboardSubPage>
              </RequireAuth>
            }
          />

          <Route
            path="/sdd-export"
            element={
              <RequireAuth>
                <DashboardSubPage>
                  <SubPageSddExport />
                </DashboardSubPage>
              </RequireAuth>
            }
          />

          <Route
            path="/sdd-validate"
            element={
              <RequireAuth>
                <DashboardSubPage>
                  <SubPageSDDValidate />
                </DashboardSubPage>
              </RequireAuth>
            }
          />

          <Route path="*" exact element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </AuthContextProvider>
  );
}
