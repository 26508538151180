import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

function SubPageInicio(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      Página Inicial ... to-do ....
    </Typography>
  );
}

export default SubPageInicio;
