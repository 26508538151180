import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import BB_API from "./api/bb-api";
import Button from "@mui/material/Button";

import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import RuleIcon from "@mui/icons-material/Rule";
import Switch from "@mui/material/Switch";
import AssignmentIcon from "@mui/icons-material/Assignment";

import { Link as RouterLink } from "react-router-dom";

import TextField from "@mui/material/TextField";
import { Assignment } from "@mui/icons-material";

function preventDefault(event) {
  event.preventDefault();
}

export default function SubPageSddValidate() {
  const [isLoading, setIsLoading] = useState(false);
  const [isDialog, setIsDialog] = useState({
    open: false,
    color: "success",
    title: "",
    message: "",
    closeText: "",
    closeAction: () => {},
  });
  const theme = useTheme();
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const handleClickFabAdd = (event) => {
    event.preventDefault();
    navigate("/erro-");
  };

  const [ficheiroRecebido, setFicheiroRecebido] = useState();
  const changeFicheiroRecebido = (event) => {
    setFicheiroRecebido(event.target.files[0]);
  };

  const [resultados, setResultados] = useState([]);

  const [ficheiroSDDValidacao, setFicheiroSDDValidacao] = useState({
    validado: false,
    data: {},
    rows: [],
  });

  useEffect(() => {
    const lista = BB_API.operation({ action: "sdd-files" });
    lista.then((dados) => {
      if (dados.success) {
        setResultados(dados.rows);
      } else {
        // erros...
        alert("erros: " + dados.message);
      }
      setIsLoading(false);
    });
  }, []);

  const handleClickValidarFicheiro = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    let dados_a_enviar = [];
    for (var campo of data.entries()) {
      dados_a_enviar = {
        ...dados_a_enviar,
        [campo[0]]: campo[1],
      };
    }
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      dados_a_enviar = {
        ...dados_a_enviar,
        file: event.target.result,
      };

      // enviar para a api ...
      const resposta = BB_API.operation({
        action: "sdd-validate-return", // obter ficheiro
        data: dados_a_enviar,
      });
      resposta.then((dados) => {
        if (dados.success) {
          setFicheiroSDDValidacao({
            validado: true,
            data: dados.data,
            rows: dados.rows,
          });

          /*
        setIsDialog({
          open: true,
          color: "success",
          title: "Sucesso!",
          message: dados.message,
          closeText: "OK",
          closeAction: () => {
            setIsDialog({ ...isDialog, open: false });
            navigate("/clientes");
          },
        });
        */
        } else {
          // erros...
          setIsDialog({
            open: true,
            color: "error",
            title: "Ocorreu um erro!",
            message: dados.message,
            closeText: "OK",
            closeAction: () => {
              setIsDialog({ ...isDialog, open: false });
            },
          });
        }
        setIsLoading(false);
      });
    });
    reader.readAsDataURL(ficheiroRecebido);
    setIsLoading(true);
    setFicheiroSDDValidacao({
      validado: false,
      data: {},
      rows: [],
    });

    setIsLoading(false);
  };

  function download(data, filename, type) {
    var file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob)
      // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    else {
      // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  const handleDownloadFicheiro = (event) => {
    event.preventDefault();
    download(
      ficheiroSDDValidacao.data.blob,
      ficheiroSDDValidacao.data.nome_ficheiro,
      "text/xml"
    );
  };

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        fullScreen={fullScreenDialog}
        open={isDialog.open}
        onClose={isDialog.closeAction}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{isDialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{isDialog.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={isDialog.closeAction} autoFocus>
            {isDialog.closeText}
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        component="form"
        onSubmit={handleClickValidarFicheiro}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={11}>
            <Title>SDD - Validar Ficheiro de Recebimentos</Title>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "right",
              padding: "0!important",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            {/*
          <Fab
            size="small"
            color="primary"
            aria-label="Adicionar"
            onClick={handleClickFabAdd}
          >
            <AddIcon />
          </Fab>
          */}
          </Grid>
        </Grid>

        <Divider sx={{ margin: "16px 0px" }} />
        {resultados && (
          <React.Fragment>
            <RadioGroup
              aria-label="Ficheiro SDD"
              defaultValue="0"
              name="sdd_file_choosen"
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Ficheiro</TableCell>
                    <TableCell>Data Cobrança</TableCell>
                    <TableCell>???</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultados.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        {row.file_name}
                        <br />
                        <small>{row.date_created}</small>
                      </TableCell>
                      <TableCell>
                        {row.date_collect}
                        <br />
                        <small>{row.file_records} registos</small>
                      </TableCell>
                      <TableCell>
                        <FormControlLabel
                          value={row.id}
                          control={<Radio />}
                          label="Seleccionar"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Divider sx={{ margin: "8px 0px" }} />
              <Grid container justifyContent="space-between" spacing={2}>
                <Grid
                  item
                  sx={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Button variant="contained" component="label">
                    {ficheiroRecebido && <span>{ficheiroRecebido.name}</span>}
                    {!ficheiroRecebido && <span>Enviar Ficheiro</span>}
                    <input
                      type="file"
                      accept=".xml"
                      hidden
                      onChange={changeFicheiroRecebido}
                    />
                  </Button>
                </Grid>
                <Grid
                  item
                  sx={{
                    textAlign: "right",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Validar Ficheiro
                  </Button>
                </Grid>
              </Grid>
            </RadioGroup>
          </React.Fragment>
        )}
        {!resultados && <p>Não foram encontrados registos.</p>}
      </Box>

      {ficheiroSDDValidacao.validado && (
        <Box component="div" sx={{ mt: 4 }}>
          <Divider sx={{ margin: "16px 0px" }} />
          <Title>SDD - Ficheiro Validado</Title>

          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID COBRANÇA</TableCell>
                <TableCell>Total Cobrança</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={999999}>
                <TableCell>
                  {ficheiroSDDValidacao.data.original_msg_id}
                  <br />
                  <strong>{ficheiroSDDValidacao.data.total_txs}</strong>{" "}
                  Registos
                </TableCell>
                <TableCell>
                  <strong>
                    {ficheiroSDDValidacao.data.total_formated_sem_iva}
                  </strong>
                  <br />
                  <small>
                    {ficheiroSDDValidacao.data.total_formated} c/ IVA
                  </small>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Divider sx={{ margin: "8px 0px" }} />

          <Typography size="small">Registos Validados:</Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <AssignmentIcon size="small" color="black" />
                </TableCell>
                <TableCell>
                  <RuleIcon size="small" color="black" />
                </TableCell>
                <TableCell>
                  Empresa/Titular
                  <br />
                  Autorização
                </TableCell>
                <TableCell>
                  Valor sem IVA
                  <br />
                  Valor Cobrado
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ficheiroSDDValidacao.rows.map((row) => (
                <TableRow key={row.EndToEndId}>
                  <TableCell sx={{ maxWidth: "40px" }}>
                    <Switch size="small" />
                  </TableCell>
                  <TableCell sx={{ maxWidth: "40px" }}>
                    {row.result_formated && (
                      <CheckIcon size="small" color="primary" />
                    )}
                    {!row.result_formated && (
                      <CloseIcon size="small" color="error" />
                    )}
                  </TableCell>
                  <TableCell>
                    {row.name}
                    <br />
                    <small>{row.mandate_formated}</small>
                  </TableCell>
                  <TableCell>
                    <strong>{row.value_formated_sem_iva}</strong>
                    <br />
                    <small>{row.value_formated} c/ IVA</small>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Divider sx={{ margin: "8px 0px" }} />
        </Box>
      )}
    </React.Fragment>
  );
}
