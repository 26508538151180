import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import BB_API from "./api/bb-api";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";

function preventDefault(event) {
  event.preventDefault();
}

export default function SubPageClientesEditar() {
  const urlParams = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isDialog, setIsDialog] = useState({
    open: false,
    color: "success",
    title: "",
    message: "",
    closeText: "",
    closeAction: () => {},
  });
  const theme = useTheme();
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down("md"));

  const [getTemSDD, setTemSDD] = useState(false);
  const changeTemSDD = (event) => {
    setTemSDD(event.target.checked);
  };

  const [getClienteStatus, setClienteStatus] = useState(false);
  const changeClienteStatus = (event) => {
    setClienteStatus(event.target.checked);
  };

  const handleClickSave = (event) => {
    event.preventDefault();
    setIsLoading(true);

    // gravar
    const data = new FormData(event.currentTarget);

    let erros = 0;
    let dados_a_enviar = {};
    for (var campo of data.entries()) {
      dados_a_enviar = {
        ...dados_a_enviar,
        [campo[0]]: campo[1],
      };

      if (campo[0] === "client_company" && campo[1] === "") {
        erros++;
      }
      if (campo[0] === "client_vat" && campo[1] === "") {
        erros++;
      }
      if (campo[0] === "client_address_street" && campo[1] === "") {
        erros++;
      }
      if (campo[0] === "client_address_postalcode" && campo[1] === "") {
        erros++;
      }
      if (campo[0] === "client_address_local" && campo[1] === "") {
        erros++;
      }
      if (campo[0] === "client_email" && campo[1] === "") {
        erros++;
      }
      if (campo[0] === "client_contact_name" && campo[1] === "") {
        erros++;
      }
    }

    if (erros > 0) {
      setIsLoading(false);
      return false;
    }
    const cliente_id = urlParams.cliente_id;
    dados_a_enviar = {
      ...dados_a_enviar,
      client_has_sdd: parseInt(data.get("client_has_sdd") || 0),
      client_status: parseInt(data.get("client_status") || 0),
      id: cliente_id,
    };

    // enviar para a api ...
    const resposta = BB_API.operation({
      action: "client-save",
      data: dados_a_enviar,
    });
    resposta.then((dados) => {
      if (dados.success) {
        setIsDialog({
          open: true,
          color: "success",
          title: "Sucesso!",
          message: dados.message,
          closeText: "OK",
          closeAction: () => {
            setIsDialog({ ...isDialog, open: false });
            navigate("/clientes");
          },
        });
      } else {
        // erros...
        setIsDialog({
          open: true,
          color: "error",
          title: "Ocorreu um erro!",
          message: dados.message,
          closeText: "OK",
          closeAction: () => {
            setIsDialog({ ...isDialog, open: false });
          },
        });
      }
      setIsLoading(false);
    });

    setIsLoading(false);
  };

  const [dadosCliente, setDadosCliente] = useState({
    client_company: "",
    client_vat: "",
    client_address_street: "",
    client_address_postalcode: "",
    client_address_local: "",
    client_email: "",
    client_phone: "",
    client_iban: "",
    client_swift: "",
    client_contact_name: "",
    client_contact_phone: "",
    client_contact_email: "",
    client_has_sdd: 0,
    client_status: 0,
    client_internal_info: "",
    client_sdd_value: "",
    client_sdd_mandate_date: "",
    client_sdd_mandate_id: "",
  });

  const changeInputValues = (event) => {
    setDadosCliente({
      ...dadosCliente,
      [event.target.name]: event.target.value,
    });
  };

  // carregar informaçao do cliente a editar
  useEffect(() => {
    if (typeof urlParams.cliente_id === "undefined") {
      navigate("/clientes");
    }

    const cliente_id = urlParams.cliente_id;
    setIsLoading(true);

    // enviar para a api ...
    const resposta = BB_API.operation({
      action: "client-get",
      data: { cliente_id: cliente_id },
    });
    resposta.then((dados) => {
      if (dados.success) {
        console.log(dados.row);
        setDadosCliente(dados.row);
        setTemSDD(parseInt(dados.row.client_has_sdd) === 1);
        setClienteStatus(parseInt(dados.row.client_status) === 1);
      } else {
        // erros...
        setIsDialog({
          open: true,
          color: "error",
          title: "Ocorreu um erro!",
          message: dados.message,
          closeText: "OK",
          closeAction: () => {
            setIsDialog({ ...isDialog, open: false });
          },
        });
      }
      setIsLoading(false);
    });

    setIsLoading(false);
  }, []);

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        fullScreen={fullScreenDialog}
        open={isDialog.open}
        onClose={isDialog.closeAction}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{isDialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{isDialog.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={isDialog.closeAction} autoFocus>
            {isDialog.closeText}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={11}>
          <Title>Clientes - Editar</Title>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            textAlign: "right",
            padding: "0!important",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          {/*
          <Fab
            size="small"
            color="primary"
            aria-label="Adicionar"
            onClick={handleClickFabAdd}
          >
            <AddIcon />
          </Fab>
          */}
        </Grid>
      </Grid>
      <Divider sx={{ margin: "16px 0px" }} />
      <Box
        component="form"
        onSubmit={handleClickSave}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          inputProps={{ maxLength: 250 }}
          id="client_company"
          label="Nome da Empresa"
          name="client_company"
          type="text"
          autoComplete="new-no-auto-fill-please"
          autoFocus
          value={dadosCliente.client_company}
          onChange={changeInputValues}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          inputProps={{ maxLength: 11 }}
          name="client_vat"
          label="NIF/VAT/NIPC"
          type="text"
          id="client_vat"
          autoComplete="new-no-auto-fill-please"
          value={dadosCliente.client_vat}
          onChange={changeInputValues}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          inputProps={{ maxLength: 128 }}
          id="client_address_street"
          name="client_address_street"
          label="Morada (Rua e Número)"
          type="text"
          autoComplete="new-no-auto-fill-please"
          value={dadosCliente.client_address_street}
          onChange={changeInputValues}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          inputProps={{ maxLength: 8 }}
          id="client_address_postalcode"
          name="client_address_postalcode"
          label="Código Postal"
          type="text"
          autoComplete="new-no-auto-fill-please"
          value={dadosCliente.client_address_postalcode}
          onChange={changeInputValues}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          inputProps={{ maxLength: 64 }}
          id="client_address_local"
          name="client_address_local"
          label="Localidade"
          type="text"
          autoComplete="new-no-auto-fill-please"
          value={dadosCliente.client_address_local}
          onChange={changeInputValues}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          inputProps={{ maxLength: 64 }}
          id="client_email"
          name="client_email"
          label="Email"
          type="text"
          autoComplete="new-no-auto-fill-please"
          value={dadosCliente.client_email}
          onChange={changeInputValues}
        />
        <TextField
          margin="normal"
          fullWidth
          inputProps={{ maxLength: 64 }}
          id="client_phone"
          name="client_phone"
          label="Telefone"
          type="text"
          autoComplete="new-no-auto-fill-please"
          value={dadosCliente.client_phone}
          onChange={changeInputValues}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          inputProps={{ maxLength: 64 }}
          id="client_contact_name"
          name="client_contact_name"
          label="Responsável: Nome"
          type="text"
          autoComplete="new-no-auto-fill-please"
          value={dadosCliente.client_contact_name}
          onChange={changeInputValues}
        />
        <TextField
          margin="normal"
          fullWidth
          inputProps={{ maxLength: 64 }}
          id="client_contact_phone"
          name="client_contact_phone"
          label="Responsável: Telefone"
          type="text"
          autoComplete="new-no-auto-fill-please"
          value={dadosCliente.client_contact_phone}
          onChange={changeInputValues}
        />
        <TextField
          margin="normal"
          fullWidth
          inputProps={{ maxLength: 64 }}
          id="client_contact_email"
          name="client_contact_email"
          label="Responsável: Email"
          type="text"
          autoComplete="new-no-auto-fill-please"
          value={dadosCliente.client_contact_email}
          onChange={changeInputValues}
        />

        <FormControlLabel
          control={
            <Checkbox
              value="1"
              name="client_has_sdd"
              color="primary"
              checked={getTemSDD}
              onChange={changeTemSDD}
            />
          }
          label="Tem SDD Activo?"
        />
        <br />

        {getTemSDD && (
          <React.Fragment>
            <TextField
              margin="normal"
              fullWidth
              inputProps={{ maxLength: 25, minLength: 25 }}
              id="client_iban"
              name="client_iban"
              label="IBAN"
              type="text"
              autoComplete="new-no-auto-fill-please"
              value={dadosCliente.client_iban}
              onChange={changeInputValues}
            />
            <TextField
              margin="normal"
              fullWidth
              inputProps={{ maxLength: 11, minLength: 11 }}
              id="client_swift"
              name="client_swift"
              label="Código swift"
              type="text"
              autoComplete="new-no-auto-fill-please"
              value={dadosCliente.client_swift}
              onChange={changeInputValues}
            />
            <TextField
              margin="normal"
              fullWidth
              inputProps={{ maxLength: 10 }}
              id="client_sdd_mandate_date"
              name="client_sdd_mandate_date"
              label="Data de assinatura"
              type="text"
              autoComplete="new-no-auto-fill-please"
              value={dadosCliente.client_sdd_mandate_date}
              onChange={changeInputValues}
            />
            <TextField
              margin="normal"
              fullWidth
              inputProps={{ maxLength: 10 }}
              id="client_sdd_mandate_id"
              name="client_sdd_mandate_id"
              label="Numero/ID da autorização"
              type="text"
              autoComplete="new-no-auto-fill-please"
              value={dadosCliente.client_sdd_mandate_id}
              onChange={changeInputValues}
            />
            <TextField
              margin="normal"
              fullWidth
              inputProps={{ maxLength: 16 }}
              id="client_sdd_contrato_id"
              name="client_sdd_contrato_id"
              label="Número do Contrato"
              type="text"
              autoComplete="new-no-auto-fill-please"
              value={dadosCliente.client_sdd_contrato_id}
              onChange={changeInputValues}
            />
            <TextField
              margin="normal"
              fullWidth
              inputProps={{ maxLength: 10 }}
              id="client_sdd_value"
              name="client_sdd_value"
              label="Valor SDD Mensal (S/ IVA)"
              type="text"
              autoComplete="new-no-auto-fill-please"
              value={dadosCliente.client_sdd_value}
              onChange={changeInputValues}
            />
            <TextField
              margin="normal"
              fullWidth
              inputProps={{ maxLength: 64 }}
              id="client_sdd_titular"
              name="client_sdd_titular"
              label="Nome Titular da Conta"
              type="text"
              autoComplete="new-no-auto-fill-please"
              helperText="Preencher apenas se for diferente do nome da empresa"
              value={dadosCliente.client_sdd_titular}
              onChange={changeInputValues}
            />
          </React.Fragment>
        )}
        <br />

        <FormControlLabel
          control={
            <Checkbox
              value="1"
              name="client_status"
              color="primary"
              checked={getClienteStatus}
              onChange={changeClienteStatus}
            />
          }
          label="Cliente Activo ?"
        />
        <br />

        <TextField
          margin="normal"
          fullWidth
          multiline
          rows={4}
          maxRows={12}
          inputProps={{ maxLength: 64000 }}
          id="client_internal_info"
          name="client_internal_info"
          label="Notas Internas"
          type="text"
          autoComplete="new-no-auto-fill-please"
          value={dadosCliente.client_internal_info}
          onChange={changeInputValues}
        />

        <Divider sx={{ margin: "8px 0px" }} />
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid
            item
            sx={{
              textAlign: "right",
              padding: "0!important",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Gravar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
