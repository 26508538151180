import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import BB_API from "./api/bb-api";

import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

import { Link as RouterLink } from "react-router-dom";

function preventDefault(event) {
  event.preventDefault();
}

export default function SubPageClientes() {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const handleClickFabAdd = (event) => {
    event.preventDefault();
    navigate("/clientes-adicionar");
  };

  const [resultados, setResultados] = useState([]);

  useEffect(() => {
    const lista = BB_API.operation({ action: "clients-list" });
    lista.then((dados) => {
      if (dados.success) {
        console.log(dados);
        setResultados(dados.rows);
      } else {
        // erros...
        alert("erros: " + dados.message);
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={11}>
          <Title>Clientes</Title>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            textAlign: "right",
            padding: "0!important",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <Fab
            size="small"
            color="primary"
            aria-label="Adicionar"
            onClick={handleClickFabAdd}
          >
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
      <Divider sx={{ margin: "16px 0px" }} />
      {resultados && (
        <React.Fragment>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Empresa</TableCell>
                <TableCell>Responsável</TableCell>
                <TableCell align="right">SDD?</TableCell>
                <TableCell align="right">Activo?</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultados.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    {row.client_company}
                    <br />
                    {row.client_vat}
                  </TableCell>
                  <TableCell>{row.client_contact_name}</TableCell>
                  <TableCell align="right">
                    {row.client_has_sdd === "1" && <CheckIcon />}
                    {row.client_has_sdd === "0" && <CloseIcon />}
                  </TableCell>
                  <TableCell align="right">
                    {row.client_status === "1" && <CheckIcon />}
                    {row.client_status === "0" && <CloseIcon />}
                  </TableCell>
                  <TableCell>
                    <RouterLink to={`/clientes-editar/${row.id}`}>
                      <EditIcon size="small" color="primary" />
                    </RouterLink>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Link
            color="primary"
            href="#"
            onClick={preventDefault}
            sx={{ mt: 3 }}
          >
            coisas...
          </Link>
        </React.Fragment>
      )}
      {!resultados && <p>Não foram encontrados registos.</p>}
    </React.Fragment>
  );
}
