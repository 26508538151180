import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import BB_API from "./api/bb-api";
import Button from "@mui/material/Button";

import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { Link as RouterLink } from "react-router-dom";

import TextField from "@mui/material/TextField";

function preventDefault(event) {
  event.preventDefault();
}

export default function SubPageSddExport() {
  const [isLoading, setIsLoading] = useState(false);
  const [isDialog, setIsDialog] = useState({
    open: false,
    color: "success",
    title: "",
    message: "",
    closeText: "",
    closeAction: () => {},
  });
  const theme = useTheme();
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const handleClickFabAdd = (event) => {
    event.preventDefault();
    navigate("/erro-");
  };

  const [resultados, setResultados] = useState([]);

  const [ficheiroSDD, setFicheiroSDD] = useState({
    gerado: false,
    data: {},
    rows: [],
  });

  const [getDataCobranca, setDataCobranca] = useState("");
  const ChangeDataCobranca = (event) => {
    setDataCobranca(event.target.value);
  };

  useEffect(() => {
    const lista = BB_API.operation({ action: "sdd-list" });
    lista.then((dados) => {
      if (dados.success) {
        setResultados(dados.rows);
      } else {
        // erros...
        alert("erros: " + dados.message);
      }
      setIsLoading(false);
    });
  }, []);

  const handleClickGerarFicheiro = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    let dados_a_enviar = [];
    for (var campo of data.entries()) {
      dados_a_enviar = {
        ...dados_a_enviar,
        [campo[0]]: campo[1],
      };
    }
    dados_a_enviar = {
      ...dados_a_enviar,
      data_cobranca: getDataCobranca,
    };
    setIsLoading(true);
    setFicheiroSDD({
      gerado: false,
      data: {},
      rows: [],
    });

    // enviar para a api ...
    const resposta = BB_API.operation({
      action: "sdd-get", // obter ficheiro
      data: dados_a_enviar,
    });
    resposta.then((dados) => {
      if (dados.success) {
        setFicheiroSDD({
          gerado: true,
          data: dados.data,
          rows: dados.rows,
        });

        /*
        setIsDialog({
          open: true,
          color: "success",
          title: "Sucesso!",
          message: dados.message,
          closeText: "OK",
          closeAction: () => {
            setIsDialog({ ...isDialog, open: false });
            navigate("/clientes");
          },
        });
        */
      } else {
        // erros...
        setIsDialog({
          open: true,
          color: "error",
          title: "Ocorreu um erro!",
          message: dados.message,
          closeText: "OK",
          closeAction: () => {
            setIsDialog({ ...isDialog, open: false });
          },
        });
      }
      setIsLoading(false);
    });

    setIsLoading(false);
  };

  function download(data, filename, type) {
    var file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob)
      // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    else {
      // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  const handleDownloadFicheiro = (event) => {
    event.preventDefault();
    download(ficheiroSDD.data.blob, ficheiroSDD.data.nome_ficheiro, "text/xml");
  };

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        fullScreen={fullScreenDialog}
        open={isDialog.open}
        onClose={isDialog.closeAction}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{isDialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{isDialog.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={isDialog.closeAction} autoFocus>
            {isDialog.closeText}
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        component="form"
        onSubmit={handleClickGerarFicheiro}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={11}>
            <Title>SDD - Gerar Ficheiro para o Banco</Title>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "right",
              padding: "0!important",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            {/*
          <Fab
            size="small"
            color="primary"
            aria-label="Adicionar"
            onClick={handleClickFabAdd}
          >
            <AddIcon />
          </Fab>
          */}
          </Grid>
        </Grid>

        <Divider sx={{ margin: "16px 0px" }} />
        {resultados && (
          <React.Fragment>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Empresa</TableCell>
                  <TableCell>IBAN</TableCell>
                  <TableCell>Valor a Cobrar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultados.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {row.client_company}
                      <br />
                      <small>
                        [ NIF: {row.client_vat} ] [ ADD:{" "}
                        {row.client_sdd_mandate_id} -{" "}
                        {row.client_sdd_mandate_date} ]
                      </small>
                    </TableCell>
                    <TableCell>
                      <small>{row.client_swift}</small>
                      <br />
                      <small>{row.client_iban}</small>
                    </TableCell>
                    <TableCell>
                      <TextField
                        margin="normal"
                        fullWidth
                        inputProps={{ maxLength: 7 }}
                        name={`${row.id}`}
                        name={`${row.id}`}
                        label="Valor (S/ IVA)"
                        type="text"
                        autoComplete="new-no-auto-fill-please"
                        defaultValue={row.client_sdd_value}
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Divider sx={{ margin: "8px 0px" }} />
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid
                item
                sx={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <TextField
                  id="getDataCobranca"
                  value={getDataCobranca}
                  onChange={ChangeDataCobranca}
                  variant="outlined"
                  label="Data de Cobrança"
                  type="date"
                />
              </Grid>

              <Grid
                item
                sx={{
                  textAlign: "right",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Gerar Ficheiro
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {!resultados && <p>Não foram encontrados registos.</p>}
      </Box>

      {ficheiroSDD.gerado && (
        <Box component="div" sx={{ mt: 4 }}>
          <Divider sx={{ margin: "16px 0px" }} />
          <Title>SDD - Ficheiro Gerado</Title>

          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Ficheiro</TableCell>
                <TableCell>Data Cobrança</TableCell>
                <TableCell>Total Cobrança</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={999999}>
                <TableCell>
                  {ficheiroSDD.data.nome_ficheiro}
                  <br />
                  <strong>{ficheiroSDD.data.total_registos}</strong> Registos
                </TableCell>
                <TableCell>{ficheiroSDD.data.data_cobranca}</TableCell>
                <TableCell>
                  <strong>{ficheiroSDD.data.total_formated_com_iva}</strong>
                  <br />
                  <small>{ficheiroSDD.data.total_formated} s/ IVA</small>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Grid container justifyContent="space-between" spacing={2}>
            <Grid
              item
              sx={{
                textAlign: "right",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleDownloadFicheiro}
              >
                Download do Ficheiro
              </Button>
            </Grid>
          </Grid>
          <Divider sx={{ margin: "8px 0px" }} />

          <Typography size="small">Registos neste ficheiro:</Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  Titular da Conta
                  <br />
                  Empresa Nome Comercial
                </TableCell>
                <TableCell>
                  Autorização
                  <br />
                  Contrato
                </TableCell>
                <TableCell>
                  TR ID
                  <br />
                  CODE
                </TableCell>
                <TableCell>Valor a Cobrar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ficheiroSDD.rows.map((row) => (
                <TableRow key={row.sdd_mandate_id}>
                  <TableCell>
                    <small>{row.sdd_titular}</small>
                    <br />
                    <small>{row.sdd_company}</small>
                  </TableCell>
                  <TableCell>
                    <small>{row.sdd_mandate_id}</small>
                    <br />
                    <small>{row.contrato}</small>
                  </TableCell>
                  <TableCell>
                    <small>{row.endToEndId}</small>
                    <br />
                    <small>{row.code}</small>
                  </TableCell>
                  <TableCell>
                    <small>{row.sdd_value_com_iva}</small>
                    <br />
                    <small>{row.sdd_value} s/ IVA</small>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </React.Fragment>
  );
}
